import * as React from "react";
import { useEffect, useState } from "react";
import {
  GetAutosetMinimumSignalQuery,
  GetLiveAutosetMinimumSignalSubscription,
  useGetAutosetMinimumSignalLazyQuery,
  useGetLiveAutosetMinimumSignalSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import Table, { ColumnsType } from "antd/lib/table";
import { DataType, LiveDataModeTableProps } from "./types";

const LiveDataTable = (props: LiveDataModeTableProps) => {
  const { chosenGatewayId } = props;
  const [autosetBaseline, setAutosetBaseline] =
    useState<GetAutosetMinimumSignalQuery["sensorflow_v_gw_autoset_min_signal"]>();
  const [autosetCurrent, setAutosetCurrent] =
    useState<GetLiveAutosetMinimumSignalSubscription["sensorflow_v_gw_autoset_min_signal"]>();
  const [dataTable, setDataTable] = useState<any[]>([]);

  const columnConfig: ColumnsType<DataType> = [
    {
      title: "Position Ids",
      responsive: ["sm"],
      dataIndex: "positionId",
    },
    {
      title: "Baseline Signal",
      responsive: ["sm"],
      dataIndex: "baselineSignal",
    },
    {
      title: "Current Minimum",
      responsive: ["sm"],
      dataIndex: "currentMinimum",
    },
  ];
  useGetLiveAutosetMinimumSignalSubscription({
    variables: {
      gatewayId: chosenGatewayId,
    },
    onSubscriptionData: (data) => setAutosetCurrent(data.subscriptionData.data?.sensorflow_v_gw_autoset_min_signal),
  });

  const [getAutosetBaselineSignal] = useGetAutosetMinimumSignalLazyQuery({
    variables: {
      gatewayId: chosenGatewayId,
    },
    onCompleted: (data) => {
      if (data.sensorflow_v_gw_autoset_min_signal) setAutosetBaseline(data.sensorflow_v_gw_autoset_min_signal);
    },
  });

  useEffect(() => {
    if (chosenGatewayId) {
      getAutosetBaselineSignal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenGatewayId]);

  useEffect(() => {
    const tempData: any[] = [];
    autosetBaseline?.map((autoset) => {
      if (autoset?.position_id) {
        autosetCurrent?.map((current) => {
          if (current?.position_id) {
            if (autoset?.position_id === current?.position_id) {
              tempData.push({
                key: autoset.position_id,
                positionId: autoset.position_id,
                baselineSignal: autoset.min_signal_strength,
                currentMinimum: current.min_signal_strength,
              });
            }
          }
          return true;
        });
      }
      return true;
    });
    setDataTable(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenGatewayId, autosetBaseline, autosetCurrent]);

  return (
    <>
      <Table
        data-testid="live-data-table"
        columns={columnConfig}
        dataSource={dataTable}
        scroll={{ x: true }}
        pagination={{ position: ["bottomCenter"] }}
      />
    </>
  );
};

export default LiveDataTable;
