import * as React from "react";
import { FC, useState, useEffect } from "react";
import { Button, Modal } from "antd";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { useLocationMetadataLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { ExclamationCircleFilled } from "@ant-design/icons";

const TimeBasedConfigurationOnboardingModal: FC = () => {
  const { locationId } = useSensorflowLocation();
  const [isOnboarded, setIsOnboarded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [getIsOnboarded] = useLocationMetadataLazyQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      if (data.locationMetadata?.isTimeBasedConfigOnboarded) {
        setIsOnboarded(data.locationMetadata?.isTimeBasedConfigOnboarded);
      }
      setLoading(true);
    },
  });

  const handleOk = () => {
    setIsOnboarded(true);
  };

  useEffect(() => {
    if (locationId && !isOnboarded && !loading) {
      getIsOnboarded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboarded, loading]);

  return (
    <>
      <Modal
        data-testid="is-time-based-config-onboarded"
        open={!isOnboarded && loading}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
        footer={
          <div className="text-center">
            <Button key="begin" type="primary" onClick={handleOk}>
              Begin Configuration
            </Button>
          </div>
        }
        centered
        destroyOnClose
      >
        <div className="text-center">
          <ExclamationCircleFilled
            className="fs-xxl mb-s text-primary"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <p className="fs-xl font-weight-semi-bold">This configuration will be applied to all your existing keys.</p>
          <p>Note: You can always modify settings on individual keys by visiting their configuration page later.</p>
        </div>
      </Modal>
    </>
  );
};

export default TimeBasedConfigurationOnboardingModal;
